import { type SVGProps } from 'react';

export function TrophyIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-6 h-6 fill-current'}
      viewBox='0 0 24 24'
    >
      <path
        fill='#fff'
        d='M21.143 5.976h-1.592l.057-.612c.048-.498-.14-.99-.52-1.359a2.118 2.118 0 00-1.473-.577H6.385c-.56 0-1.095.21-1.474.577a1.668 1.668 0 00-.519 1.36l.057.61H2.857c-.53.001-1.038.19-1.413.524a1.694 1.694 0 00-.587 1.26V9.8c.003 1.26.558 2.471 1.546 3.373.989.902 2.333 1.425 3.747 1.456 1.143 1.48 2.983 2.41 4.993 2.526v3.602H8.57a.88.88 0 00-.742.382.694.694 0 000 .765.88.88 0 00.742.382h6.858a.88.88 0 00.742-.382.694.694 0 000-.765.88.88 0 00-.742-.382h-2.572v-3.602c2.01-.116 3.85-1.047 4.993-2.526 1.414-.032 2.758-.554 3.747-1.456.988-.902 1.543-2.112 1.546-3.374V7.76c0-.473-.212-.927-.587-1.26a2.132 2.132 0 00-1.413-.524zM2.571 9.798V7.76c.002-.14.129-.253.286-.255h1.735l.403 4.31c.039.392.122.78.25 1.156a3.752 3.752 0 01-1.928-1.19 3.089 3.089 0 01-.746-1.983zm9.886 5.861h-.914c-1.217-.005-2.388-.416-3.283-1.151-.896-.736-1.45-1.742-1.557-2.824L6.1 5.233a.238.238 0 01.075-.194.302.302 0 01.21-.082h11.23c.08 0 .156.03.21.082.054.053.08.123.074.194l-.603 6.454c-.107 1.08-.663 2.087-1.558 2.822-.895.734-2.065 1.144-3.281 1.15zm8.972-5.86a3.089 3.089 0 01-.746 1.982 3.751 3.751 0 01-1.928 1.19c.128-.377.211-.764.25-1.156l.403-4.31h1.735c.157.002.284.115.286.255v2.038z'
      ></path>
    </svg>
  );
}
