export function clientLoader() {
  if (process.env.NODE_ENV !== 'development') {
    throw new Response('Not Found', { status: 404 });
  }
  return null;
}

function IconDiplay(props: { icon: React.FC; name: string }) {
  const { icon } = props;
  let body;
  try {
    body = icon({ className: 'w-5 h-5 fill-current' });
  } catch (error) {
    body = <div className='text-sms text-red-001'>Render Failed</div>;
  }
  return (
    <div className='flex flex-col items-center justify-center p-4 border border-white gap-4'>
      {body}
      <span className='text-sm'>{props.name}</span>
    </div>
  );
}

export function Component() {
  const modules = import.meta.glob('../components/icons/**/*.tsx', {
    eager: true,
  });
  return (
    <div className='h-full text-white overflow-auto scollbar grid grid-cols-6 gap-0 p-10'>
      {Object.entries(modules).map(([_, value]) => {
        const icons = value as Record<string, React.FC>;
        return Object.keys(icons).map((iconKey) => {
          return (
            <IconDiplay key={iconKey} icon={icons[iconKey]} name={iconKey} />
          );
        });
      })}
    </div>
  );
}
