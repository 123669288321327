export function MagicInboxIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className}
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      fill='none'
      viewBox='0 0 60 60'
    >
      <mask
        id='mask0_2356_267895'
        style={{ maskType: 'alpha' }}
        width='60'
        height='60'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#C4C4C4' d='M0 0H60V60H0z'></path>
      </mask>
      <g mask='url(#mask0_2356_267895)'>
        <path
          fill='#fff'
          d='M45.046 36.595c-.75-1.482-2.297-2.38-4.031-2.38H18.747c-1.735 0-3.235.898-4.032 2.38l-4.313 8.353c-.14.27-.234.539-.234.808v11.318c0 1.976 1.688 3.592 3.75 3.592H45.89c2.063 0 3.75-1.616 3.75-3.592V45.8c0-.314-.093-.584-.234-.808l-4.36-8.398zm-26.955 1.617c.14-.225.375-.404.656-.404h22.268a.71.71 0 01.656.404l3 5.793h-7.125c-.891 0-1.641.584-1.828 1.437-.047.18-1.032 4.67-5.814 4.67-4.64 0-5.719-4.176-5.813-4.67-.187-.853-.937-1.437-1.828-1.437H15.09l3-5.793zm27.8 18.907H13.917v-9.521h6.938c1.079 2.65 3.798 6.108 9.048 6.108s7.97-3.459 9.048-6.108h6.938v9.52zM21.732 14.117a2.057 2.057 0 000-2.958l-4.078-4.025a2.123 2.123 0 00-2.997 0 2.057 2.057 0 000 2.958l4.078 4.025c.836.826 2.16.826 2.997 0zM32.05 9.92c.383-.378.627-.894.592-1.479v-5.71c0-1.17-.941-2.098-2.092-2.064-1.185 0-2.126.93-2.126 2.099v5.71c0 1.169.941 2.098 2.092 2.064.627 0 1.15-.241 1.533-.62zM17.41 24.367c-.383.379-.94.585-1.498.585l-5.82.034C8.941 25.021 8 24.093 8 22.922c0-1.169.941-2.098 2.126-2.098h5.786c1.15-.034 2.091.895 2.091 2.064.035.585-.209 1.101-.592 1.48zM45.154 20.859c-1.185 0-2.126.929-2.126 2.098 0 1.17.941 2.098 2.092 2.064h5.785c.558 0 1.116-.206 1.5-.585.383-.378.627-.894.592-1.479 0-1.17-.941-2.098-2.092-2.064l-5.75-.034zM39.403 11.262l4.113-4.059c.802-.79 2.126-.79 2.962.035a2.057 2.057 0 010 2.958L42.4 14.22a2.123 2.123 0 01-2.997 0 2.057 2.057 0 010-2.958z'
        ></path>
        <path
          fill='#FBB707'
          d='M38.492 19.293l-4.517-.375a1.086 1.086 0 01-.918-.673l-1.723-4.08c-.382-.897-1.684-.897-2.067 0l-1.722 4.08a1.086 1.086 0 01-.919.673l-4.554.412a1.088 1.088 0 00-.65 1.908l3.406 2.881c.344.263.459.674.344 1.086l-1.033 4.303c-.191.936.803 1.684 1.645 1.16l3.866-2.282a1.244 1.244 0 011.148 0l3.866 2.282c.841.524 1.875-.261 1.645-1.16l-1.033-4.303a1.187 1.187 0 01.344-1.085l3.407-2.882c.843-.673.46-1.87-.535-1.945z'
        ></path>
      </g>
    </svg>
  );
}
