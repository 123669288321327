const FacebookIcon = (): JSX.Element => {
  return (
    <svg
      className='w-6 h-6 fill-current inline'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M23 11.5701C23 5.18167 17.8497 0 11.5 0C5.15029 0 0 5.18167 0 11.5701C0 17.3438 4.20469 22.1322 9.70312 23V14.9145H6.7832V11.5701H9.70312V9.02103C9.70312 6.12173 11.4214 4.51955 14.0471 4.51955C15.3049 4.51955 16.6211 4.74553 16.6211 4.74553V7.59285H15.1701C13.7416 7.59285 13.2969 8.48546 13.2969 9.40067V11.5701H16.4863L15.9765 14.9145H13.2969V23C18.7953 22.1322 23 17.346 23 11.5701Z' />
    </svg>
  );
};

export { FacebookIcon };
