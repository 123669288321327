export function TutorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 25 21'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
      {...props}
    >
      <circle
        cx='15'
        cy='11'
        r='9.5'
        fill='#303436'
        stroke='url(#paint0_linear_71679_5252)'
      />
      <g clipPath='url(#clip0_71679_5252)'>
        <path
          d='M4.43038 2.40278C4.52284 2.13205 4.90574 2.13205 4.99819 2.40278L5.43844 3.692C5.7386 4.571 6.42899 5.26138 7.30798 5.56154L8.5972 6.00179C8.86793 6.09425 8.86793 6.47715 8.5972 6.5696L7.30798 7.00985C6.42899 7.31002 5.7386 8.0004 5.43844 8.87939L4.99819 10.1686C4.90574 10.4393 4.52284 10.4393 4.43038 10.1686L3.99013 8.87939C3.68997 8.0004 2.99958 7.31002 2.12059 7.00985L0.831374 6.5696C0.560639 6.47715 0.560639 6.09425 0.831373 6.00179L2.12059 5.56154C2.99958 5.26138 3.68997 4.571 3.99013 3.692L4.43038 2.40278Z'
          fill='white'
        />
        <path
          d='M8.50096 0.415687C8.54718 0.28032 8.73863 0.280319 8.78486 0.415687L9.00499 1.0603C9.15507 1.49979 9.50026 1.84498 9.93976 1.99507L10.5844 2.21519C10.7197 2.26142 10.7197 2.45287 10.5844 2.49909L9.93976 2.71922C9.50026 2.8693 9.15507 3.21449 9.00499 3.65399L8.78486 4.2986C8.73864 4.43397 8.54718 4.43397 8.50096 4.2986L8.28083 3.65399C8.13075 3.21449 7.78556 2.8693 7.34606 2.71922L6.70145 2.49909C6.56609 2.45287 6.56609 2.26142 6.70145 2.21519L7.34606 1.99507C7.78556 1.84498 8.13075 1.49979 8.28083 1.0603L8.50096 0.415687Z'
          fill='white'
        />
      </g>
      <path
        opacity='0.5'
        d='M13.8551 13.7294V13.5483C13.8587 12.9268 13.9137 12.4315 14.0202 12.0621C14.1303 11.6928 14.2901 11.3945 14.4996 11.1673C14.7092 10.94 14.9613 10.734 15.256 10.5494C15.4762 10.4073 15.6733 10.2599 15.8473 10.1072C16.0213 9.95455 16.1598 9.78587 16.2628 9.60121C16.3658 9.413 16.4173 9.20348 16.4173 8.97266C16.4173 8.72763 16.3587 8.51278 16.2415 8.32812C16.1243 8.14347 15.9663 8.00142 15.7674 7.90199C15.5721 7.80256 15.3555 7.75284 15.1175 7.75284C14.8867 7.75284 14.6683 7.80433 14.4624 7.90732C14.2564 8.00675 14.0877 8.15589 13.9563 8.35476C13.8249 8.55007 13.7539 8.79332 13.7433 9.08452H11.57C11.5877 8.37429 11.7582 7.78835 12.0813 7.3267C12.4045 6.86151 12.8324 6.51527 13.3651 6.288C13.8977 6.05717 14.4854 5.94176 15.1282 5.94176C15.8349 5.94176 16.4599 6.05895 17.0032 6.29332C17.5465 6.52415 17.9727 6.85973 18.2816 7.30007C18.5906 7.74041 18.745 8.27131 18.745 8.89276C18.745 9.30824 18.6758 9.67756 18.5373 10.0007C18.4023 10.3203 18.2124 10.6044 17.9673 10.853C17.7223 11.098 17.4329 11.32 17.0991 11.5188C16.8185 11.6857 16.5877 11.8597 16.4066 12.0408C16.229 12.2219 16.0959 12.4315 16.0071 12.6694C15.9219 12.9073 15.8775 13.2003 15.8739 13.5483V13.7294H13.8551ZM14.9098 17.1385C14.5547 17.1385 14.2511 17.0142 13.9989 16.7656C13.7504 16.5135 13.6278 16.2116 13.6314 15.8601C13.6278 15.5121 13.7504 15.2138 13.9989 14.9652C14.2511 14.7166 14.5547 14.5923 14.9098 14.5923C15.2472 14.5923 15.5437 14.7166 15.7994 14.9652C16.055 15.2138 16.1847 15.5121 16.1882 15.8601C16.1847 16.0945 16.1225 16.3093 16.0018 16.5046C15.8846 16.6964 15.7301 16.8509 15.5384 16.968C15.3466 17.0817 15.1371 17.1385 14.9098 17.1385Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_71679_5252'
          x1='25'
          y1='1.5144'
          x2='1.15294'
          y2='13.0408'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0029FF' />
          <stop offset='1' stopColor='#FE0653' />
        </linearGradient>
        <clipPath id='clip0_71679_5252'>
          <rect width='11' height='11' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
