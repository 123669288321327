const PlayerWarningIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={`${className ? className : 'fill-current w-16 h-16'}`}
      viewBox='0 0 64 68'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32 59.4287C47.1484 59.4287 59.4286 47.1485 59.4286 32.0001C59.4286 16.8517 47.1484 4.57153 32 4.57153C16.8516 4.57153 4.57141 16.8517 4.57141 32.0001C4.57141 47.1485 16.8516 59.4287 32 59.4287Z'
          fill='white'
          fillOpacity='0.6'
        />
        <path
          d='M32 53.3613C35.0297 53.3613 37.4857 50.9053 37.4857 47.8756C37.4857 44.8459 35.0297 42.3899 32 42.3899C28.9703 42.3899 26.5143 44.8459 26.5143 47.8756C26.5143 50.9053 28.9703 53.3613 32 53.3613Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M26.0001 16.849C26.0001 13.6029 28.6864 10.9714 32.0001 10.9714C35.3138 10.9714 38 13.6029 38 16.849C38 17.2963 37.9493 17.7319 37.8524 18.1506L36.0001 34.4816C36.0001 36.6457 34.2093 38.4 32.0001 38.4C29.791 38.4 28.0001 36.6457 28.0001 34.4816L26.1477 18.1506C26.0511 17.7319 26.0001 17.2963 26.0001 16.849Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='-4'
          y='0'
          width='72'
          height='72'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export { PlayerWarningIcon };
