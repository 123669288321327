const ImageUploadIcon = ({
  isOnHover = false,
}: {
  isOnHover?: boolean;
}): JSX.Element => {
  if (isOnHover) {
    return (
      <svg
        width='107'
        height='78'
        viewBox='0 0 107 78'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0 19C0 17.8954 0.895431 17 2 17H88C89.1046 17 90 17.8954 90 19V76C90 77.1046 89.1046 78 88 78H2C0.89543 78 0 77.1046 0 76V19Z'
          fill='white'
        />
        <path
          d='M4 15C4 13.8954 4.89543 13 6 13H92C93.1046 13 94 13.8954 94 15V72C94 73.1046 93.1046 74 92 74H6C4.89543 74 4 73.1046 4 72V15Z'
          fill='#01ACC4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M92 14H6C5.44772 14 5 14.4477 5 15V72C5 72.5523 5.44772 73 6 73H92C92.5523 73 93 72.5523 93 72V15C93 14.4477 92.5523 14 92 14ZM6 13C4.89543 13 4 13.8954 4 15V72C4 73.1046 4.89543 74 6 74H92C93.1046 74 94 73.1046 94 72V15C94 13.8954 93.1046 13 92 13H6Z'
          fill='white'
        />
        <path
          d='M36 13C36 17.9706 31.9706 22 27 22C22.0294 22 18 17.9706 18 13C18 8.02944 22.0294 4 27 4C31.9706 4 36 8.02944 36 13Z'
          fill='white'
        />
        <path
          d='M31.9897 31.7835L14.7775 60.9844C13.9916 62.3177 14.9528 64 16.5005 64H93.6428C95.1582 64 96.1231 62.3804 95.4016 61.0478L66.7185 8.06991C65.9826 6.71078 64.049 6.66431 63.2487 7.98653L44.6321 38.744C43.9178 39.9242 42.2512 40.0439 41.3756 38.9778L35.2582 31.5297C34.3763 30.456 32.6952 30.5866 31.9897 31.7835Z'
          fill='white'
        />
        <circle cx='94' cy='13' r='13' fill='white' />
        <path
          d='M88.8572 13C88.8572 12.5266 89.2409 12.1429 89.7143 12.1429H98.2857C98.7591 12.1429 99.1429 12.5266 99.1429 13C99.1429 13.4734 98.7591 13.8571 98.2857 13.8571H89.7143C89.2409 13.8571 88.8572 13.4734 88.8572 13Z'
          fill='#01ACC4'
        />
        <path
          d='M94 7.85715C94.4734 7.85715 94.8572 8.2409 94.8572 8.71429V17.2857C94.8572 17.7591 94.4734 18.1429 94 18.1429C93.5266 18.1429 93.1429 17.7591 93.1429 17.2857L93.1429 8.71429C93.1429 8.2409 93.5266 7.85715 94 7.85715Z'
          fill='#01ACC4'
        />
      </svg>
    );
  }
  return (
    <svg
      width='107'
      height='78'
      viewBox='0 0 107 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 19C0 17.8954 0.895431 17 2 17H88C89.1046 17 90 17.8954 90 19V76C90 77.1046 89.1046 78 88 78H2C0.89543 78 0 77.1046 0 76V19Z'
        fill='#232325'
      />
      <path
        d='M4 15C4 13.8954 4.89543 13 6 13H92C93.1046 13 94 13.8954 94 15V72C94 73.1046 93.1046 74 92 74H6C4.89543 74 4 73.1046 4 72V15Z'
        fill='#101012'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M92 14H6C5.44772 14 5 14.4477 5 15V72C5 72.5523 5.44772 73 6 73H92C92.5523 73 93 72.5523 93 72V15C93 14.4477 92.5523 14 92 14ZM6 13C4.89543 13 4 13.8954 4 15V72C4 73.1046 4.89543 74 6 74H92C93.1046 74 94 73.1046 94 72V15C94 13.8954 93.1046 13 92 13H6Z'
        fill='#232325'
      />
      <path
        d='M36 13C36 17.9706 31.9706 22 27 22C22.0294 22 18 17.9706 18 13C18 8.02944 22.0294 4 27 4C31.9706 4 36 8.02944 36 13Z'
        fill='#232325'
      />
      <path
        d='M31.9897 31.7835L14.7775 60.9844C13.9916 62.3177 14.9528 64 16.5005 64H93.6428C95.1582 64 96.1231 62.3804 95.4016 61.0478L66.7185 8.06991C65.9826 6.71078 64.049 6.66431 63.2487 7.98653L44.6321 38.744C43.9178 39.9242 42.2512 40.0439 41.3756 38.9778L35.2582 31.5297C34.3763 30.456 32.6952 30.5866 31.9897 31.7835Z'
        fill='#232325'
      />
      <circle cx='94' cy='13' r='13' fill='#232325' />
      <path
        d='M88.8572 13C88.8572 12.5266 89.2409 12.1429 89.7143 12.1429H98.2857C98.7591 12.1429 99.1429 12.5266 99.1429 13C99.1429 13.4734 98.7591 13.8571 98.2857 13.8571H89.7143C89.2409 13.8571 88.8572 13.4734 88.8572 13Z'
        fill='white'
      />
      <path
        d='M94 7.85715C94.4734 7.85715 94.8572 8.2409 94.8572 8.71429V17.2857C94.8572 17.7591 94.4734 18.1429 94 18.1429C93.5266 18.1429 93.1429 17.7591 93.1429 17.2857L93.1429 8.71429C93.1429 8.2409 93.5266 7.85715 94 7.85715Z'
        fill='white'
      />
    </svg>
  );
};

export { ImageUploadIcon };
