const HalfIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5'}
      viewBox='0 0 78 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='39' cy='39' r='35' fill='#FBB707' />
      <path
        d='M22.7738 26.3901L17.7862 28.8726L15.1821 23.5694L24.112 18.8076L30.2094 19.395L27.8163 44.236L21.1167 43.5906L22.7738 26.3901Z'
        fill='black'
      />
      <path
        d='M46.1927 51.0673L50.2353 47.4287C51.7752 45.9414 52.9003 44.8066 53.6105 44.0243C54.3215 43.2176 54.7832 42.5614 54.9956 42.0555C55.2324 41.5505 55.3614 40.9807 55.3827 40.3463C55.4342 38.8091 54.728 38.016 53.264 37.9669C51.6292 37.9121 49.8321 37.9617 47.8727 38.1159L47.0297 38.1243L46.9392 33.1747C49.3078 32.4724 51.8463 32.1667 54.5547 32.2575C57.1655 32.345 59.0971 33.0205 60.3494 34.2838C61.6261 35.548 62.2247 37.3635 62.1454 39.7303C62.1028 40.9991 61.8944 42.1158 61.5201 43.0804C61.1701 44.0458 60.5762 45.0029 59.7383 45.9519C58.9004 46.901 57.7147 48.0215 56.1813 49.3136L53.37 51.198L62.0076 51.4876L61.8174 57.1606L46.0061 56.6305L46.1927 51.0673Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.6565 15.6664L29.6565 64.6664L25.3672 62.8281L46.3672 13.8281L50.6565 15.6664Z'
        fill='black'
      />
    </svg>
  );
};

export { HalfIcon };
