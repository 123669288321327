const MicrophoneWithStarIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.1 1C11.245 1 13 2.755 13 4.9C13 5.87228 12.632 6.77333 12.0278 7.46615L11.7795 6.86332C11.3556 5.83432 10.0235 5.72518 9.407 6.53591C8.12075 5.96389 7.24795 4.67919 7.15 3.21C6.76 3.665 6.5 4.25 6.5 4.9C6.5 6.22195 7.5364 7.42197 8.95974 7.49635L8.65537 8.23529L7.25469 8.37111C7.19534 8.37686 7.13796 8.38572 7.08258 8.39747L7.215 8.28C6.565 7.955 5.98 7.37 5.655 6.72L2.08 10.75C1.885 11.075 1.885 11.53 2.145 11.79C2.405 12.115 2.86 12.115 3.185 11.855L6.08512 9.28231C5.91366 9.77957 6.01177 10.3725 6.41965 10.7746L4.03 12.895C3.445 13.35 2.6 13.545 1.755 13.155L1.105 13.805C0.845 14.065 0.455 14.065 0.195 13.805C-0.065 13.545 -0.065 13.155 0.195 12.895L0.845 12.245C0.455 11.53 0.585 10.62 1.105 9.97L3.25 7.565L3.055 7.305C2.795 7.045 2.795 6.655 3.055 6.395C3.315 6.135 3.705 6.135 3.965 6.395L4.16 6.59L5.2 5.355C4.94 2.95 6.825 1 9.1 1ZM11.2341 8.16489L10.8549 7.24418C10.8008 7.113 10.6969 7.03467 10.5832 7.0092C10.445 7.10494 10.295 7.18957 10.1384 7.26042L9.50412 8.80036C10.1367 8.72983 10.7261 8.5047 11.2341 8.16489ZM8.08704 9.29509L7.1709 10.108L7.13188 10.0722C6.87716 9.83821 7.0136 9.39918 7.35121 9.36644L8.08704 9.29509ZM11.7 4.90001V4.9C11.7 3.275 10.205 1.975 8.515 2.365C8.06 4.25 9.75 5.94 11.635 5.485C11.7 5.29 11.7 5.09501 11.7 4.90001Z'
        fill='white'
      />
      <path
        d='M10.1451 7.24418C10.2792 6.91861 10.7208 6.91861 10.8549 7.24418L11.5584 8.95224C11.6144 9.08804 11.7368 9.18104 11.8776 9.19469L13.6488 9.36644C13.9864 9.39918 14.1228 9.83821 13.8681 10.0722L12.5318 11.2995C12.4255 11.3971 12.3787 11.5476 12.4098 11.6918L12.8009 13.506C12.8755 13.8519 12.5183 14.1232 12.2267 13.9422L10.6973 12.9927C10.5757 12.9172 10.4243 12.9172 10.3027 12.9927L8.77327 13.9422C8.48173 14.1232 8.12453 13.8519 8.19907 13.506L8.59016 11.6918C8.62126 11.5476 8.57449 11.3971 8.46824 11.2995L7.13189 10.0722C6.87717 9.83821 7.01361 9.39918 7.35122 9.36644L9.12239 9.19469C9.26321 9.18104 9.38564 9.08804 9.44158 8.95224L10.1451 7.24418Z'
        fill='#FBB707'
      />
    </svg>
  );
};

export { MicrophoneWithStarIcon };
