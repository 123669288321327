export const GuestIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={props.className ?? 'w-6.5 h-6.5 fill-current'}
      viewBox='0 0 47 46'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill='#8B9294'
        d='M36.141 33.82l-.004-.11v-.027a21.262 21.262 0 00-.749-4.124 13.032 13.032 0 00-5.821-6.893.598.598 0 01-.29-.42.586.586 0 01.144-.488 8.138 8.138 0 002.01-5.034 8.124 8.124 0 00-1.606-5.174 8.29 8.29 0 00-4.521-3.054 8.377 8.377 0 00-5.46.4 8.249 8.249 0 00-4.017 3.678 8.106 8.106 0 00-.818 5.351 8.17 8.17 0 002.737 4.687 8.341 8.341 0 005.098 1.976c.052.005.104.008.156.008a9.526 9.526 0 015.436 1.707 9.351 9.351 0 013.45 4.488c.085.255.152.517.2.781a2.01 2.01 0 01-.164 1.258c-.19.39-.5.711-.888.915a17.044 17.044 0 01-8.115 2.011 17.043 17.043 0 01-8.095-2.085 1.956 1.956 0 01-.885-1.01 1.928 1.928 0 01-.02-1.335 11.885 11.885 0 012.279-3.867 1.795 1.795 0 00.35-1.892 1.85 1.85 0 00-.545-.737 1.88 1.88 0 00-1.76-.335c-.297.094-.567.26-.783.484a12.986 12.986 0 00-3.128 5.461 21.27 21.27 0 00-.47 3.278 1.818 1.818 0 00.732 1.598A20.735 20.735 0 0023 39.429c4.479 0 8.834-1.444 12.407-4.113l.024-.02.078-.065.065-.059.059-.06.069-.074.043-.053.068-.09.034-.052.06-.096.034-.065.045-.09.033-.084.03-.079.029-.097.02-.073.018-.099.013-.078.008-.09.006-.094-.002-.078zM18.582 16.403c0-.894.268-1.767.77-2.51a4.562 4.562 0 012.053-1.665 4.623 4.623 0 012.642-.257 4.59 4.59 0 012.342 1.237 4.504 4.504 0 011.251 2.314 4.47 4.47 0 01-.26 2.611 4.53 4.53 0 01-1.684 2.029 4.612 4.612 0 01-5.773-.564 4.5 4.5 0 01-1.34-3.195zM45.598 29.852l.069-.092.037-.058.06-.099.036-.068.046-.095.033-.087.033-.084.029-.1.02-.078.02-.104.013-.082.009-.095.006-.099v-.081l-.005-.115v-.03a11.477 11.477 0 00-.425-2.313 7.498 7.498 0 00-2.262-3.242.64.64 0 01-.158-.779 5.117 5.117 0 10-4.908 2.86c.078.01.156.014.234.014.893 0 1.756.327 2.426.918.142.135.267.286.373.45a1.26 1.26 0 01-.698 1.91 7.2 7.2 0 01-1.15.245 1.976 1.976 0 00-1.755 1.972 1.93 1.93 0 002.16 1.866 11.185 11.185 0 005.301-2.114l.036-.025.066-.047.023-.02.085-.07.066-.058.063-.066.07-.076.047-.058z'
      ></path>
      <path
        fill='#8B9294'
        d='M.411 29.852l-.069-.092-.037-.058-.06-.099-.036-.068-.046-.095-.033-.087-.033-.084-.029-.1-.02-.078-.02-.104-.013-.082-.009-.095L0 28.71v-.081l.005-.115v-.03c.062-.784.204-1.559.425-2.313a7.498 7.498 0 012.262-3.242.641.641 0 00.158-.779 5.06 5.06 0 01-.514-2.558 5.117 5.117 0 115.422 5.418 1.94 1.94 0 01-.234.014c-.893 0-1.756.327-2.426.918a2.523 2.523 0 00-.373.45 1.26 1.26 0 00.698 1.91c.376.113.761.195 1.15.245a1.976 1.976 0 011.755 1.972 1.929 1.929 0 01-2.16 1.866 11.185 11.185 0 01-5.301-2.114l-.036-.025-.066-.047-.023-.02-.085-.07-.066-.058-.064-.066-.069-.076-.047-.058z'
      ></path>
    </svg>
  );
};
