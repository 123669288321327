const CloseTabIcon = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      fill='none'
      viewBox='0 0 14 14'
    >
      <rect width='2' height='10' x='10' y='2' fill='#fff' rx='1'></rect>
      <path
        fill='#fff'
        d='M7.734 7.676a.972.972 0 00.173-1.117.908.908 0 00-.173-.235L3.606 2.29a.94.94 0 00-1.318-.037.95.95 0 00-.288.679.967.967 0 00.277.683L5.314 6.55A.634.634 0 015.5 7a.645.645 0 01-.185.45l-3.062 2.897a.98.98 0 00-.292.694 1 1 0 00.277.7.917.917 0 001.288-.014l4.21-4.051z'
      ></path>
    </svg>
  );
};

export { CloseTabIcon };
