import { type SVGProps } from 'react';

export function TeamChatIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 14'
      className={props.className || 'w-3 h-3.5 fill-current'}
    >
      <g fill='#fff' clipPath='url(#clip0_24544_178119)'>
        <path d='M7.784 11.017v-.047a8.786 8.786 0 00-.2-1.452c-.271-1.015-.813-1.89-1.52-2.42-.023-.016-.047-.047-.059-.063a.274.274 0 01-.023-.093v-.094a.375.375 0 01.035-.078c.33-.5.518-1.124.53-1.765a3.53 3.53 0 00-.424-1.826c-.283-.531-.706-.906-1.178-1.078a1.674 1.674 0 00-1.436.14c-.448.266-.825.719-1.06 1.297a3.663 3.663 0 00-.212 1.89c.094.64.341 1.217.718 1.654.377.422.848.656 1.343.687h.035c.506 0 1.013.219 1.425.61.412.39.73.936.907 1.576.023.094.035.188.047.281a.77.77 0 01-.047.438.579.579 0 01-.224.327 3.602 3.602 0 01-2.132.703c-.741 0-1.472-.25-2.131-.734a.682.682 0 01-.236-.359.794.794 0 010-.468c.13-.5.342-.969.6-1.359a.758.758 0 00.119-.312.79.79 0 00-.165-.625.428.428 0 00-.224-.125c-.07-.03-.165-.015-.236.016a.493.493 0 00-.212.172A5.174 5.174 0 001 9.83c-.059.375-.094.765-.118 1.156 0 .11.012.218.047.312a.625.625 0 00.154.25C2.025 12.485 3.167 13 4.345 13s2.308-.515 3.25-1.452h.012l.024-.016.011-.015.012-.016.024-.031.012-.016.023-.031v-.047l.012-.031.012-.016.011-.031.012-.031.012-.031.012-.032v-.155-.032zM3.179 4.881c0-.313.07-.625.2-.89.13-.266.318-.469.542-.594a.91.91 0 01.695-.093c.235.062.447.218.612.437.165.234.283.53.33.827a2.03 2.03 0 01-.07.922 1.557 1.557 0 01-.448.718c-.2.172-.424.265-.66.265-.318 0-.624-.156-.848-.468-.235-.297-.353-.703-.353-1.124zM8.031 4.079a.89.89 0 000 1.015.741.741 0 01.142.452.61.61 0 01-.142.406.89.89 0 000 1.015c.106.14.248.219.389.219a.499.499 0 00.389-.219c.588-.78.588-2.108 0-2.888-.212-.266-.566-.281-.778 0z'></path>
        <path d='M10.22 2.236a.499.499 0 00-.388-.219.462.462 0 00-.377.219.89.89 0 000 1.015c.46.609.719 1.42.719 2.295s-.26 1.671-.719 2.296a.89.89 0 000 1.015.46.46 0 00.377.202.462.462 0 00.377-.218c.671-.875 1.036-2.046 1.036-3.295s-.365-2.436-1.024-3.31z'></path>
      </g>
      <defs>
        <clipPath id='clip0_24544_178119'>
          <path
            fill='#fff'
            d='M0 0H10.363V14H0z'
            transform='translate(.882)'
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
