export function MagnifyPlusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? `w-4 h-4 fill-current`}
      fill='currentColor'
      viewBox='0 0 512 512'
    >
      <path d='M205.195 47.353q42.42 0 78.92 20.716 36.502 21.704 58.205 58.205 20.717 37.487 20.717 78.92t-20.717 78.922q-21.703 36.5-58.204 58.204-36.501 20.717-78.921 20.717t-78.921-20.717q-36.501-21.703-58.205-58.204-20.716-37.488-20.716-78.921t20.716-78.921q21.704-36.501 58.205-58.205 36.5-20.716 78.92-20.716m0 363.036q73.988-.986 127.26-44.393L464.647 498.19q16.771 13.811 33.542 0 13.811-16.77 0-33.542L365.996 332.455q43.407-53.272 44.393-127.26-1.973-86.813-60.177-145.018T205.195 0Q118.382 1.973 60.177 60.177T0 205.195q1.973 86.813 60.177 145.017t145.018 60.177m-23.677-118.381q1.973 21.703 23.677 23.676 21.703-1.973 23.676-23.676V228.87h63.137q21.703-1.973 23.676-23.676-1.973-21.704-23.676-23.677H228.87v-63.136q-1.973-21.704-23.676-23.677-21.704 1.973-23.677 23.677v63.136h-63.136q-21.704 1.973-23.677 23.677 1.973 21.703 23.677 23.676h63.136z'></path>
    </svg>
  );
}
