export function SparklingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      className={`${props.className ?? 'w-5 h-5 fill-current'}`}
    >
      <g clipPath='url(#clip0_48347_111231)'>
        <path d='M7.809 10.429a.452.452 0 010-.858l2.421-.806a3.613 3.613 0 002.285-2.286l.806-2.42a.452.452 0 01.858 0l.806 2.421a3.61 3.61 0 002.286 2.285l2.42.806a.451.451 0 010 .858l-2.42.806a3.611 3.611 0 00-2.286 2.285l-.806 2.421a.45.45 0 01-.858 0l-.806-2.421a3.613 3.613 0 00-2.285-2.285l-2.421-.806zm-6.374 4.829a.271.271 0 010-.515l1.453-.484a2.17 2.17 0 001.37-1.372l.485-1.452a.271.271 0 01.515 0l.483 1.452a2.168 2.168 0 001.372 1.372l1.452.483a.27.27 0 010 .515l-1.452.484a2.167 2.167 0 00-1.372 1.372l-.483 1.452a.271.271 0 01-.515 0l-.484-1.453a2.167 2.167 0 00-1.371-1.37l-1.453-.485zM.124 6.42a.181.181 0 010-.342l.967-.323c.433-.144.772-.482.915-.915l.323-.967a.181.181 0 01.342 0l.323.967a1.445 1.445 0 00.915.915l.967.323a.181.181 0 010 .342l-.967.323a1.445 1.445 0 00-.915.915l-.323.966a.181.181 0 01-.342 0l-.323-.968a1.443 1.443 0 00-.915-.915l-.966-.32H.124z'></path>
      </g>
      <defs>
        <clipPath id='clip0_48347_111231'>
          <path d='M0 0H20V20H0z' transform='rotate(-90 10 10)'></path>
        </clipPath>
      </defs>
    </svg>
  );
}
