export function AttachDocsIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 15 14'
    >
      <path
        fill='#fff'
        d='M7.954 2.663l-4.843 4.73c-1.169 1.141-1.275 2.894-.236 3.909 1.039 1.014 2.834.911 4.003-.23l5.146-5.026c.167-.163.182-.413.034-.558-.149-.145-.405-.13-.572.033L6.34 10.546c-.835.815-2.118.89-2.86.165-.741-.725-.666-1.977.169-2.792l4.843-4.73c.501-.49 1.27-.534 1.716-.1.445.436.4 1.187-.101 1.676l-4.844 4.73c-.167.163-.423.178-.572.033-.148-.145-.133-.395.034-.558L9.57 4.24c.167-.164.182-.414.033-.559-.148-.145-.404-.13-.571.033l-4.844 4.73c-.5.49-.546 1.24-.101 1.675.445.435 1.215.39 1.715-.098l4.844-4.73c.835-.815.91-2.068.168-2.792-.742-.725-2.024-.651-2.859.164z'
      ></path>
    </svg>
  );
}
